<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Add Invoice
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form" v-model="formValid">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="template"
                    label="Select Template"
                    :items="templateOptions"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    dense
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="reference"
                    label="Invoice Reference"
                    outlined
                    dense
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" md="12">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedDate"
                        label="Pick Invoice Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        hide-details
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" md="12">
                  <v-file-input
                    v-model="selectedFile"
                    label="Select Invoice"
                    prepend-icon=""
                    outlined
                    dense
                    hide-details
                    :rules="[rules.required]"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="AddInvoice()"
            class="text-white"
            :disabled="!formValid || loading"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              color="white"
            ></v-progress-circular>
            <span v-else>Save</span>
          </v-btn>
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "add-invoice-dialog",
  props: {
    vendor_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      name: "",
      reference: "",
      selectedDate: null,
      selectedFile: null,
      invoiceDate: null,
      dialog: true,
      rules: {
        required: (value) => !!value || "This is a required field.",
      },
      formValid: true,
      templateOptions: [
        { name: "A4 - Table", value: 1 },
        { name: "OK Produce", value: 2 },
      ],
      template: null,
      loading: false
    };
  },
  methods: {
    AddInvoice() {
      let alertData = {};
      if (!this.selectedFile) return;
      this.$emit("start-loader");
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.selectedFile);
      formData.append("vendor_id", this.vendor_id);
      formData.append("date", this.selectedDate);
      formData.append("reference", this.reference);
      formData.append("name", this.name);
      formData.append("template_id", this.template);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/invoices", formData)
        .then((res) => {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("stop-loader");
          this.loading = false;
          this.$emit("reload-invoices");
          this.$emit("show-alert", alertData);
          this.closeDialog();
        })
        .catch((err) => {
          console.log(err);
          alertData.alertText = err.data.message;
          alertData.alertType = "error";
          this.$emit("stop-loader");
          this.loading = false;
          this.$emit("show-alert", alertData);
          this.closeDialog();
        });
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
  },
};
</script>
