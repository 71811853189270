<template>
  <v-container>
    <v-data-table
  :headers="headers"
  :items="items"
  :search="search"
  item-key="upc"
  class="elevation-1"
  :loading="loading"
  striped
  height="78vh"
  fixed-header
  :footer-props="footerProps"
  theme="dark"
  :server-items-length="totalItems"
  :items-per-page="itemsPerPage"
  @update:options="updateTableOptions"
>
  <template v-slot:top>
    <v-toolbar flat>
      <v-btn color="#8b1a29" class="ml-2 text-white" @click="showAddInventoryDialog = true">
        <v-icon left>mdi-plus</v-icon>
        Add Item
      </v-btn>

      <file-upload
        @start-loader="loading = true"
        @stop-loader="loading = false"
        @reload-inventory="getInventory"
        @show-alert="showAlertData"
      ></file-upload>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="w40"
        outlined
        dense
        @input="getInventory()"
      ></v-text-field>
    </v-toolbar>
  </template>

      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <template v-slot:[`item.unit_size`]="{ item }">
        <span class="text-align-center">{{
          item.unit_size ? item.unit_size : "-"
        }}</span>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span class="text-align-center no-wrap">{{
          item.description ? item.description : "-"
        }}</span>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        <span class="text-align-center">{{
          item.brand ? item.brand : "-"
        }}</span>
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        <span class="text-align-center">{{
          item.cost ? item.cost : "-"
        }}</span>
      </template>

      <template v-slot:[`item.stock`]="{ item }">
        <span class="text-align-center">{{
          item.stock ? item.stock : "-"
        }}</span>
      </template>

      <template v-slot:[`item.price_by_unit`]="{ item }">
        <span class="text-align-center">{{
          item.price_by_unit ? item.price_by_unit : "-"
        }}</span>
      </template>

      <template v-slot:[`item.sell_by_unit`]="{ item }">
        <span class="text-align-center">{{
          item.sell_by_unit ? item.sell_by_unit : "-"
        }}</span>
      </template>

      <template v-slot:[`item.sku`]="{ item }">
        <span class="text-align-center">{{
          item.sku ? item.sku : "-"
        }}</span>
      </template>

      <template v-slot:[`item.subcategory`]="{ item }">
        <span class="text-align-center">{{
          item.subcategory ? item.subcategory : "-"
        }}</span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span class="text-align-center">{{
          item.price ? item.price : "-"
        }}</span>
      </template>

      <template v-slot:[`item.sale_price`]="{ item }">
        <span class="text-align-center">{{
          item.sale_price ? item.sale_price : "-"
        }}</span>
      </template>
      
      <template v-slot:[`item.category`]="{ item }">
        <span class="text-align-center no-wrap">{{
          item.category ? item.category : "-"
        }}</span>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="text-align-center no-wrap">{{
          item.name ? item.name : "-"
        }}</span>
      </template>

      <template v-slot:[`item.vendor`]="{ item }">
        <span class="text-align-center">{{
          item.vendor ? item.vendor : "-"
        }}</span>
      </template>

      <template v-slot:[`item.retail_price`]="{ item }">
        <span class="text-align-center">{{
          item.retail_price ? item.retail_price : "-"
        }}</span>
      </template>

      <template v-slot:[`item.pack`]="{ item }">
        <span class="text-align-center">{{ item.pack ? item.pack : "-" }}</span>
      </template>

      <template v-slot:[`item.current_margin`]="{ item }">
        <span class="text-align-center">{{
          item.current_margin ? item.current_margin : "-"
        }}</span>
      </template>

      <template v-slot:[`item.new_margin`]="{ item }">
        <span class="text-align-center">{{
          item.new_margin ? item.new_margin : "-"
        }}</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span class="no-wrap">{{ formattedDate(item.created_at) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span class="no-wrap">
          <span>
            <v-icon @click="viewInventoryDetail(item)">mdi-eye</v-icon>
          </span>
          <span class="ml-1"
            ><v-icon @click="editInventory(item)">mdi-pencil</v-icon></span
          >
          <span
            ><v-icon class="text-red" @click="deleteInventory(item)"
              >mdi-delete</v-icon
            ></span
          >
        </span>
      </template>
    </v-data-table>

    <add-inventory-dialog
      v-if="showAddInventoryDialog"
      @close-dialog="showAddInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <view-inventory-dialog
      v-if="showViewInventoryDialog"
      v-model="showViewInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showViewInventoryDialog = false"
    />

    <edit-inventory-dialog
      v-if="showEditInventoryDialog"
      v-model="showEditInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showEditInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <delete-inventory-dialog
      v-if="showDeleteInventoryDialog"
      v-model="showDeleteInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showDeleteInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/FileUpload/FileUpload";
import AddInventoryDialog from "@/components/Inventory/AddInventoryDialog";
import ViewInventoryDialog from "@/components/Inventory/ViewInventoryDialog";
import EditInventoryDialog from "@/components/Inventory/EditInventoryDialog";
import DeleteInventoryDialog from "@/components/Inventory/DeleteInventoryDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "listing-data",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 20,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header" },
        { text: "SKU", value: "sku", class: "table-header" },
        { text: "Name", value: "name", class: "table-header", width: "20%" },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
        },
        { text: "Brand", value: "brand", class: "table-header", width: "8%" },
        { text: "Category", value: "category", class: "table-header" },
        { text: "Sub Category", value: "subcategory", class: "table-header" },
        { text: "Vendor", value: "vendor", class: "table-header" },
        { text: "Price", value: "price", class: "table-header" },
        { text: "Sale Price", value: "sale_price", class: "table-header" },
        { text: "Cost", value: "cost", class: "table-header" },
        { text: "Stock", value: "stock", class: "table-header" },
        {
          text: "Price By Unit",
          value: "price_by_unit",
          class: "table-header",
        },
        { text: "Sell By Unit", value: "sell_by_unit", class: "table-header" },
        {
          text: "Retail Price",
          value: "retail_price",
          class: "table-header",
          width: "10%",
        },
        {
          text: "Unit Size",
          value: "unit_size",
          class: "table-header",
          width: "8%",
        },
        { text: "Pack", value: "pack", class: "table-header" },
        {
          text: "Current Margin",
          value: "current_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "New Margin",
          value: "new_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500
        ],
      },
      items: [],
      showAddInventoryDialog: false,
      showViewInventoryDialog: false,
      showEditInventoryDialog: false,
      showDeleteInventoryDialog: false,
      selectedInventory: {},
      currentPage: 1,
      totalItems: 0
    };
  },
  components: {
    FileUpload,
    AddInventoryDialog,
    ViewInventoryDialog,
    EditInventoryDialog,
    AlertComponent,
    DeleteInventoryDialog,
  },
  mounted() {
    document.title = "Grocer App | Inventory";
    this.getInventory();
  },
  methods: {
    async getInventory() {
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/inventories?page=" + this.currentPage + "&per_page=" + this.itemsPerPage + "&search=" + this.search) 
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data.data;
            this.totalItems = res.data.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    updateTableOptions({ page, itemsPerPage }) {
      if (this.currentPage !== page || this.itemsPerPage !== itemsPerPage) {
        this.currentPage = page;
        this.itemsPerPage = itemsPerPage;
        this.getInventory();
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInventoryDetail(item) {
      this.selectedInventory = item;
      this.showViewInventoryDialog = true;
    },
    editInventory(item) {
      this.selectedInventory = item;
      this.showEditInventoryDialog = true;
    },
    deleteInventory(item) {
      this.selectedInventory = item;
      this.showDeleteInventoryDialog = true;
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
  text-wrap: nowrap !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.no-wrap {
  white-space: nowrap;
}

.text-red {
  color: red !important;
}
</style>
