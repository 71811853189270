<template>
  <!-- View Dialog Component -->
  <v-dialog max-width="1050" v-model="showViewInventoryDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3">
        View Item
      </v-card-title>

      <v-tabs v-model="tab" background-color="#8b1a29" dark>
        <v-tab value="1">Inventory Detail</v-tab>
        <v-tab value="2">Update History</v-tab>
      </v-tabs>

      <v-card-text class="mt-3">
        <v-tabs-items v-model="tab">
          <!-- Detail Tab -->
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc"
                    label="UPC"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sku"
                    label="SKU"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.description"
                    label="Description"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.vendor"
                    label="Vendor"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.category"
                    label="Category"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.subcategory"
                    label="Sub Category"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.price"
                    label="Price"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sale_price"
                    label="Sale Price"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.retail_price"
                    label="Retail Price"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.cost"
                    label="Cost"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.stock"
                    label="Stock"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.unit_size"
                    label="Unit Size"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.pack"
                    label="Pack"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.current_margin"
                    label="Current Margin"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.new_margin"
                    label="New Margin"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.price_by_unit"
                    label="Price By Unit"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sell_by_unit"
                    label="Sell By Unit"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <!-- Update History Tab -->
          <v-tab-item>
            <v-container>
              <v-data-table
                :headers="historyTableHeaders"
                :items="historyItems"
                item-key="id"
                class="elevation-1"
              >
                <template v-slot:[`item.number`]="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:[`item.old_value`]="{ item }">
                  <span class="text-align-center">{{
                    item.old_value ? item.old_value : "-"
                  }}</span>
                </template>
                <template v-slot:[`item.field`]="{ item }">
                  <span class="text-capitalize">{{
                    item.field.replace(/_/g, " ")
                  }}</span>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ formattedDate(item.created_at) }}</span>
                </template>
              </v-data-table>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "view-inventory-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inventoryItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewInventoryDialog: this.value,
      historyItems: [],
      tab: "1",
      localInventoryItem: { ...this.inventoryItem },
      historyTableHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Field", value: "field", class: "table-header" },
        { text: "Old Value", value: "old_value", class: "table-header" },
        { text: "New Value", value: "new_value", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header" },
      ],
    };
  },
  watch: {
    value(val) {
      this.showViewInventoryDialog = val;
    },
    showViewInventoryDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    async getHistory() {
      try {
        let items = await this.$axios.get(
          process.env.VUE_APP_API_URL + "/update-history",
          {
            params: {
              id: this.localInventoryItem.id,
            },
          }
        );

        if (items.data && items.data.status) {
          this.historyItems = items.data.data;
        } else {
          this.historyItems = [];
        }
      } catch (error) {
        this.historyItems = [];
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.tab = "1";
      this.$emit("close-dialog");
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>
