<template>
  <v-dialog max-width="700" persistent v-model="dialog">
    <v-card>
      <!-- Header -->
      <v-card-title class="headline grey lighten-3"> Add User </v-card-title>

      <!-- Form Content -->
      <v-card-text class="mt-3">
        <v-form ref="addUserForm" v-model="formValid">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.name"
                  label="Name"
                  outlined
                  dense
                  type="text"
                  required
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.email"
                  label="Email"
                  outlined
                  dense
                  type="email"
                  required
                  hide-details
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.phone"
                  label="Phone"
                  outlined
                  dense
                  type="text"
                  required
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.address"
                  label="Address"
                  outlined
                  dense
                  type="text"
                  required
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="togglePasswordVisibility"
                  outlined
                  dense
                  required
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="item.password_confirmation"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="togglePasswordVisibility"
                  outlined
                  dense
                  required
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- Footer -->
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#8b1a29"
          @click="AddUser"
          class="text-white"
          :disabled="!formValid"
        >
          Save
        </v-btn>
        <v-btn text @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "add-user-dialog",
  data() {
    return {
      item: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        password_confirmation: "",
      },
      dialog: true,
      showPassword: false,
      formValid: true,
      rules: {
        required: (value) => !!value || "This is a required field.",
        email: (value) => {
          const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
          return pattern.test(value) || "Please enter a valid email address.";
        },
      },
    };
  },
  methods: {
    async AddUser() {
      let alertData = {};
      if (this.$refs.addUserForm.validate()) {
        try {
          let res = await this.$axios.post(
            process.env.VUE_APP_API_URL + "/users",
            this.item
          );

          if (res.data.status) {
            alertData.alertText = res.data.message;
            alertData.alertType = "success";
            this.$emit("reload-users");
          } else {
            alertData.alertText = res.data.message;
            alertData.alertType = "error";
          }

          this.$emit("show-alert", alertData);
          this.$emit("close-dialog");
          this.dialog = false;
        } catch (error) {
          alertData.alertText = error.response.data.message;
          alertData.alertType = "error";
          this.$emit("show-alert", alertData);
          this.$emit("close-dialog");
          this.dialog = false;
        }
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
