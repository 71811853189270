<template>
  <v-container :fluid="true">
    <v-toolbar flat>
      <v-toolbar-title class="text-h5">
        Invoice OCR Data # {{ invoiceId }}
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      height="69vh"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn @click="showAddInvoiceDetailDialog = true" class="button">
            <v-icon left>mdi-plus</v-icon>
            Add Item
          </v-btn>
          <v-btn @click="downloadCSV" class="button">
            <v-icon left>mdi-download</v-icon>
            Download CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>

      <!-- Display row number -->
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <!-- Editable fields on double click -->
      <template v-slot:[`item.created_at`]="{ item }">
        <span>
          {{ formattedDate(item.created_at) }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status == 'old'"
          color="secondary"
          variant="flat"
          class="pill"
        >
          {{ item.status }}
        </v-chip>
        <v-chip color="green" class="pill" variant="flat" v-else>
          {{ item.status }}
        </v-chip>
      </template>

      <!-- Editable delivery_date field with date picker -->
      <template v-slot:[`item.delivery_date`]="{ item }">
        <span
          v-if="!isEditing(item, 'delivery_date')"
          @dblclick="editField(item, 'delivery_date')"
        >
          {{ formattedDate(item.delivery_date) }}
        </span>
        <v-menu
          v-else
          v-model="datePickerVisible"
          close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.delivery_date"
              v-bind="attrs"
              v-on="on"
              readonly
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.delivery_date"
            @input="saveField(item, 'delivery_date')"
            @change="closeDatePicker"
          ></v-date-picker>
        </v-menu>
      </template>

      <template v-slot:[`item.upc`]="{ item }">
        <span v-if="!isEditing(item, 'upc')" @dblclick="editField(item, 'upc')">
          {{ item.upc ? item.upc : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.upc"
          @blur="saveField(item, 'upc')"
          @keyup.enter="saveField(item, 'upc')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span
          v-if="!isEditing(item, 'description')"
          @dblclick="editField(item, 'description')"
        >
          {{ item.description ? item.description : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.description"
          @blur="saveField(item, 'description')"
          @keyup.enter="saveField(item, 'description')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.size`]="{ item }">
        <span
          v-if="!isEditing(item, 'size')"
          @dblclick="editField(item, 'size')"
        >
          {{ item.size ? item.size : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.size"
          @blur="saveField(item, 'size')"
          @keyup.enter="saveField(item, 'size')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.pack`]="{ item }">
        <span
          v-if="!isEditing(item, 'pack')"
          @dblclick="editField(item, 'pack')"
        >
          {{ item.pack ? item.pack : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.pack"
          @blur="saveField(item, 'pack')"
          @keyup.enter="saveField(item, 'pack')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        <span
          v-if="!isEditing(item, 'quantity')"
          @dblclick="editField(item, 'quantity')"
        >
          {{ item.quantity ? item.quantity : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.quantity"
          @blur="saveField(item, 'quantity')"
          @keyup.enter="saveField(item, 'quantity')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.quantity_received`]="{ item }">
        <span
          v-if="!isEditing(item, 'quantity_received')"
          @dblclick="editField(item, 'quantity_received')"
        >
          {{ item.quantity_received ? item.quantity_received : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.quantity_received"
          @blur="saveField(item, 'quantity_received')"
          @keyup.enter="saveField(item, 'quantity_received')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.ret_price`]="{ item }">
        <span
          v-if="!isEditing(item, 'ret_price')"
          @dblclick="editField(item, 'ret_price')"
        >
          {{ item.ret_price ? item.ret_price : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.ret_price"
          @blur="saveField(item, 'ret_price')"
          @keyup.enter="saveField(item, 'ret_price')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.ret_gp`]="{ item }">
        <span
          v-if="!isEditing(item, 'ret_gp')"
          @dblclick="editField(item, 'ret_gp')"
        >
          {{ item.ret_gp ? item.ret_gp : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.ret_gp"
          @blur="saveField(item, 'ret_gp')"
          @keyup.enter="saveField(item, 'ret_gp')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.ret_profit`]="{ item }">
        <span
          v-if="!isEditing(item, 'ret_profit')"
          @dblclick="editField(item, 'ret_profit')"
        >
          {{ item.ret_profit ? item.ret_profit : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.ret_profit"
          @blur="saveField(item, 'ret_profit')"
          @keyup.enter="saveField(item, 'ret_profit')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.case_price`]="{ item }">
        <span
          v-if="!isEditing(item, 'case_price')"
          @dblclick="editField(item, 'case_price')"
        >
          {{ item.case_price ? item.case_price : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.case_price"
          @blur="saveField(item, 'case_price')"
          @keyup.enter="saveField(item, 'case_price')"
          dense
        ></v-text-field>
      </template>

      <template v-slot:[`item.extended_case_price`]="{ item }">
        <span
          v-if="!isEditing(item, 'extended_case_price')"
          @dblclick="editField(item, 'extended_case_price')"
        >
          {{ item.extended_case_price ? item.extended_case_price : "-" }}
        </span>
        <v-text-field
          v-else
          v-model="item.extended_case_price"
          @blur="saveField(item, 'extended_case_price')"
          @keyup.enter="saveField(item, 'extended_case_price')"
          dense
        ></v-text-field>
      </template>

      <!-- Action buttons for each row -->
      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-icon @click="viewInvoiceDetail(item)">mdi-eye</v-icon>
        </span>
        <span>
          <v-icon @click="editInvoiceDetail(item)">mdi-pencil</v-icon>
        </span>
        <span>
          <v-icon class="text-red" @click="deleteInvoiceDetail(item)"
            >mdi-delete</v-icon
          >
        </span>
      </template>
    </v-data-table>

    <add-invoice-detail-dialog
      v-if="showAddInvoiceDetailDialog"
      v-model="showAddInvoiceDetailDialog"
      @close-dialog="showAddInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
      :invoice_id="invoiceId"
    />

    <delete-invoice-detail-dialog
      v-if="showDeleteInvoiceDetailDialog"
      v-model="showDeleteInvoiceDetailDialog"
      :detailItem="selectedDetail"
      @close-dialog="showDeleteInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
    />

    <view-invoice-detail-dialog
      v-if="showviewInvoiceDetailDialog"
      v-model="showviewInvoiceDetailDialog"
      :invoiceDetail="selectedDetail"
      @close-dialog="showviewInvoiceDetailDialog = false"
    />

    <edit-invoice-detail-dialog
      v-if="showEditInvoiceDetailDialog"
      v-model="showEditInvoiceDetailDialog"
      :invoiceDetail="selectedDetail"
      @close-dialog="showEditInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/AddInvoiceDetailDialog";
import DeleteInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/DeleteInvoiceDetailDialog";
import ViewInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/ViewInvoiceDetailDialog";
import EditInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/EditInvoiceDetailDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "invoice-detail-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
        },
        {
          text: "Delivery Date",
          value: "delivery_date",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Size",
          value: "size",
          class: "table-header",
          width: "5%",
        },
        {
          text: "Pack",
          value: "pack",
          class: "table-header",
        },
        {
          text: "Quantity",
          value: "quantity",
          class: "table-header",
        },
        {
          text: "Quantity Received",
          value: "quantity_received",
          class: "table-header",
        },
        {
          text: "Amount",
          value: "amount",
          class: "table-header",
        },
        {
          text: "Price",
          value: "price",
          class: "table-header",
        },
        {
          text: "Case Price",
          value: "case_price",
          class: "table-header",
        },
        {
          text: "Extended Case Price",
          value: "extended_case_price",
          class: "table-header",
        },
        {
          text: "RET Price",
          value: "ret_price",
          class: "table-header",
        },
        {
          text: "RET Profit",
          value: "ret_profit",
          class: "table-header",
        },
        {
          text: "RET GP",
          value: "ret_gp",
          class: "table-header",
        },
        {
          text: "Status",
          value: "status",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "8%",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddInvoiceDetailDialog: false,
      showDeleteInvoiceDetailDialog: false,
      showviewInvoiceDetailDialog: false,
      showEditInvoiceDetailDialog: false,
      selectedDetail: {},
      selectedVendor: null,
      invoiceId: null,
      editingItem: null,
      editingField: "",
      datePickerVisible: false,
    };
  },
  components: {
    AddInvoiceDetailDialog,
    AlertComponent,
    DeleteInvoiceDetailDialog,
    ViewInvoiceDetailDialog,
    EditInvoiceDetailDialog,
  },
  mounted() {
    document.title = "Grocer App | Invoice Detail";
  },
  created() {
    this.invoiceId = this.$route.params.invoice_id;
    this.getInvoiceDetail();
  },
  methods: {
    async getInvoiceDetail() {
      this.items = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoice/data/" + this.invoiceId)
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showviewInvoiceDetailDialog = true;
    },
    editInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showEditInvoiceDetailDialog = true;
    },
    deleteInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showDeleteInvoiceDetailDialog = true;
    },
    isEditing(item, field) {
      return this.editingItem === item && this.editingField === field;
    },
    editField(item, field) {
      this.editingItem = item;
      this.editingField = field;
    },
    saveField(item, field) {
      console.log(field);
      this.updateInvoiceDetail(item);

      // Clear editing state
      this.editingItem = null;
      this.editingField = "";
    },
    async updateInvoiceDetail(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/invoice/data/" + item.id,
        item
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    closeDatePicker() {
      this.datePickerVisible = false;
    },
    downloadCSV() {
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "invoice_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateCSV() {
      const header = this.headers
        .filter((h) => h.value !== "action")
        .map((h) => h.text)
        .join(",");

      const rows = this.items.map((item) =>
        this.headers
          .filter((h) => h.value !== "action")
          .map((h) => this.formatCSVField(item[h.value]))
          .join(",")
      );

      return [header, ...rows].join("\n");
    },
    formatCSVField(value) {
      if (value === null || value === undefined) {
        return "";
      }
      const escapedValue = String(value).replace(/"/g, '""');
      return `"${escapedValue}"`;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}
.v-application .primary--text {
  color: #8b1a29 !important;
}
.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}
.text-red {
  color: red;
}
.text-white {
  color: white;
}
.v-select {
  margin-top: 24px;
  width: 50%;
  max-width: 20%;
}
.button {
  margin-right: 20px;
  background-color: #8b1a29 !important;
  color: #fff;
  float: right;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1888px;
  }
}

@media (min-width: 1264px) {
  /* .container {
    max-width: 1440px;
  } */
}

.container {
  background-color: #fdf5f5;
}

.pill {
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
}

.pill.green {
  background-color: #5ba167 !important;
  border-color: #5ba167 !important;
}
</style>
