<template>
  <v-dialog v-model="showPreviewInvoiceDialog" max-width="1600" persistent>
    <v-card class="custom-dialog">
      <!-- Header (Fixed) -->
      <v-card-title class="headline grey lighten-3">
        Invoice Preview
      </v-card-title>

      <!-- Scrollable Content -->
      <v-card-text class="scrollable-image-container">
        <v-img :src="src" width="100%"></v-img>
      </v-card-text>

      <!-- Footer (Fixed) -->
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('close-dialog')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "preview-invoice-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    src: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showPreviewInvoiceDialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.showPreviewInvoiceDialog = val;
    },
    showPreviewInvoiceDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.$emit("close-dialog");
      }
    },
  },
};
</script>

<style scoped>
/* Add a fixed height and make the image section scrollable */
.custom-dialog {
  max-height: 90vh; /* Increase modal height to 90% of the viewport */
  display: flex;
  flex-direction: column;
}

.scrollable-image-container {
  flex: 1;
  overflow-y: auto;
  max-height: 70vh; /* Control the height of the scrollable area */
  margin-top: 30px;
  margin-top: 50px;
}
</style>
