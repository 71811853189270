<template>
  <v-container>
    <v-sheet class="table-container" tile>
      <v-data-table
        :headers="computedHeaders"
        :items="items"
        class="elevation-1"
        hide-default-header
        item-key="upc"
        :loading="loading"
        :search="search"
        striped
        height="78vh"
        fixed-header
        :footer-props="footerProps"
        :custom-filter="customFilter"
        theme="dark"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text class="text-h5">
              Invoice Comparison # {{ invoiceId }}
            </v-text>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="w40"
              outlined
              dense
            ></v-text-field>
          </v-toolbar>
        </template>
        <!-- Custom header with two levels -->
        <template v-slot:header>
          <thead>
            <!-- First-level headers -->
            <tr>
              <th rowspan="2" class="text-center second-header">UPC</th>
              <!-- Invoice header -->
              <th colspan="10" class="text-center invoice-header">Invoice</th>
              <!-- Inventory header -->
              <th colspan="8" class="text-center inventory-header">
                Inventory
              </th>
              <!-- Comparison header -->
              <th colspan="3" class="text-center comparison-header">
                Comparison
              </th>
            </tr>
            <!-- Second-level headers -->
            <tr>
              <th class="text-center second-header">Description</th>
              <th class="text-center second-header">Size</th>
              <th class="text-center second-header">Pack</th>
              <th class="text-center second-header">Quantity</th>
              <th class="text-center second-header">Case Price</th>
              <th class="text-center second-header">Extended Case Price</th>
              <th class="text-center second-header">RET Price</th>
              <th class="text-center second-header">RET Profit</th>
              <th class="text-center second-header">RET GP</th>
              <th class="text-center second-header">Quantity Received</th>

              <th class="text-center second-header">Description</th>
              <th class="text-center second-header">Unit Size</th>
              <th class="text-center second-header">Pack</th>
              <th class="text-center second-header">Cost</th>
              <th class="text-center second-header">Retail Price</th>
              <th class="text-center second-header">Vendor</th>
              <th class="text-center second-header">New Margin</th>
              <th class="text-center second-header">Current Margin</th>

              <th class="text-center second-header">Cost Diff.</th>
              <th class="text-center second-header">Size Diff.</th>
              <th class="text-center second-header">Pack Diff.</th>
            </tr>
          </thead>
        </template>

        <!-- Custom body to display data -->
        <template v-slot:item="{ item }">
          <tr>
            <!-- UPC column -->
            <td class="text-center">{{ item.upc }}</td>

            <!-- Invoice columns -->
            <td
              v-for="(value, key) in item.invoice"
              class="text-center"
              :key="key"
            >
              {{ value ? value : "-" }}
            </td>

            <!-- Inventory columns -->
            <td
              v-for="(value, key) in item.inventory"
              class="text-center"
              :key="key"
            >
              {{ value ? value : "-" }}
            </td>

            <!-- Comparison columns -->
            <td
              v-for="(value, key) in item.comparison"
              class="text-center"
              :key="key"
            >
              <span v-if="key != 'upc_match'"> {{ value }} </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      invoiceId: null,
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      search: "",
      loading: false,
    };
  },
  methods: {
    async getComparisonDetail() {
      this.items = [];
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/invoice/comparison/" + this.invoiceId
        )
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data;
            this.items.map((item) => {
              delete item.comparison.upc_match;
              if (item.inventory.length === 0) {
                item.inventory = {
                  description: null,
                  unit_size: null,
                  pack: null,
                  cost: null,
                  retail_price: null,
                  vendor: null,
                  new_margin: null,
                  current_margin: null,
                };
              }
              if (item.comparison.length === 0) {
                item.comparison = {
                  case_price_vs_cost_diff: null,
                  size_diff: null,
                  pack_diff: null,
                };
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    customFilter(value, search, item) {
      const lowerSearch = search.toLowerCase();

      // Check top-level UPC field
      if (item.upc && item.upc.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      // Check nested 'invoice' fields
      if (item.invoice) {
        const invoiceValues = Object.values(item.invoice);
        for (let i = 0; i < invoiceValues.length; i++) {
          if (
            invoiceValues[i] &&
            String(invoiceValues[i]).toLowerCase().includes(lowerSearch)
          ) {
            return true;
          }
        }
      }

      // Check nested 'inventory' fields
      if (item.inventory) {
        const inventoryValues = Object.values(item.inventory);
        for (let i = 0; i < inventoryValues.length; i++) {
          if (
            inventoryValues[i] &&
            String(inventoryValues[i]).toLowerCase().includes(lowerSearch)
          ) {
            return true;
          }
        }
      }

      // Check nested 'comparison' fields
      if (item.comparison) {
        const comparisonValues = Object.values(item.comparison);
        for (let i = 0; i < comparisonValues.length; i++) {
          if (
            comparisonValues[i] &&
            String(comparisonValues[i]).toLowerCase().includes(lowerSearch)
          ) {
            return true;
          }
        }
      }

      // Return false if nothing matches
      return false;
    },
  },
  computed: {
    computedHeaders() {
      // Empty headers to prevent rendering default headers
      return [];
    },
  },
  created() {
    this.invoiceId = this.$route.params.invoice_id;
    this.getComparisonDetail();
  },
};
</script>

<style scoped>
/* Custom styling for the table */
th {
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap; /* Ensure the text does not wrap */
}

/* Scroll horizontally when content overflows */
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Invoice header background color */
th.invoice-header {
  background-color: #e1e2ff !important;
  font-size: 14px !important;
}

/* Inventory header background color */
th.inventory-header {
  background-color: #ffff5f !important;
  font-size: 14px !important;
}

/* Comparison header background color */
th.comparison-header {
  background-color: #ffaf49 !important;
  font-size: 14px !important;
}

/* General styling for table data cells */
td {
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap; /* Ensure the text does not wrap */
}

@media (min-width: 1904px) {
  .container {
    max-width: 1888px;
  }
}

.container {
  background-color: #fdf5f5 !important;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1440px;
  }
}

.second-header {
  background-color: #8b1a29 !important;
  color: #fff !important;
  font-weight: bold !important;
}
</style>
