<template>
  <v-dialog max-width="700" v-model="showEditUserDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3"> Update User </v-card-title>

      <v-card-text class="mt-3">
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localUser.name"
                  label="Name"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localUser.phone"
                  label="Phone"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localUser.address"
                  label="Address"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localUser.password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="togglePasswordVisibility"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  v-model="localUser.password_confirmation"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="togglePasswordVisibility"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#8b1a29"
          @click="updateUser(localUser)"
          style="color: white"
          >Update</v-btn
        >
        <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "view-vendor-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditUserDialog: this.value,
      localUser: { ...this.user },
      showPassword: false,
    };
  },
  watch: {
    value(val) {
      this.showEditUserDialog = val;
    },
    showEditUserDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    async updateUser(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/users/" + item.id,
        item
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-vendors");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    handleCloseViewDialog() {
      this.$emit("close-dialog");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
