import Vue from "vue";
import VueRouter from "vue-router";
import SideBar from "@/components/common/SideBar";
import LoginComponent from "@/components/common/LoginComponent";
import InvoiceDetailListing from "@/components/Invoices/InvoiceDetail/InvoiceDetailListing";
import InvoiceComparison from "@/components/Invoices/InvoiceComparison";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: LoginComponent,
    name: "login",
  },
  {
    path: "/items",
    component: SideBar,
    name: "items",
    meta: { requiresAuth: true },
  },
  {
    path: "/histories",
    component: SideBar,
    name: "histories",
    meta: { requiresAuth: true },
  },
  {
    path: "/vendors",
    component: SideBar,
    name: "vendors",
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    component: SideBar,
    name: "invoices",
    meta: { requiresAuth: true },
  },
  {
    path: "/invoice/:invoice_id",
    component: InvoiceDetailListing,
    name: "invoice_detail_listing",
    meta: { requiresAuth: true },
  },
  {
    path: "/invoice/comparison/:invoice_id",
    component: InvoiceComparison,
    name: "invoice_comparison",
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    component: SideBar,
    name: "users",
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// Navigation guard to check for auth
router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("grocer_user");
  let loggedIn = JSON.parse(user)?.token;
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
