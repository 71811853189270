<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Add Item
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.upc"
                    label="UPC"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.vendor"
                    label="Vendor"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.retail_price"
                    label="Retail Price"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.unit_size"
                    label="Unit Size"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.pack"
                    label="Pack"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.current_margin"
                    label="Current Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.new_margin"
                    label="New Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#8b1a29" @click="AddInventory(item)" class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "add-inventory-dialog",

  data() {
    return {
      item: {
        upc: "",
        description: "",
        vendor: "",
        retail_price: "",
        cost: "",
        unit_size: "",
        pack: "",
        new_margin: "",
        current_margin: "",
      },
      dialog: true,
    };
  },
  methods: {
    async AddInventory() {
      let alertData = {};
      try {
        let res = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/inventories/",
          {
            upc: this.item.upc,
            description: this.item.description,
            vendor: this.item.vendor,
            retail_price: this.item.retail_price,
            unit_size: this.item.unit_size,
            pack: this.item.pack,
            new_margin: this.item.new_margin,
            current_margin: this.item.current_margin,
            cost: this.item.cost,
          }
        );

        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-inventory");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }

        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      } catch (error) {
        alertData.alertText = error.response.data.message;
        alertData.alertType = "error";
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
  },
};
</script>
