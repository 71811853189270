<template>
  <v-alert
    :type="data.alertType"
    dismissible
    class="custom-alert"
    v-model="showAlert"
  >
    <span class="text-capitalize">
      {{ data.alertText }}
    </span>
  </v-alert>
</template>

<script>
export default {
  name: "alert-component",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAlert: this.value,
    };
  },
  watch: {
    value(val) {
      this.showAlert = val;
    },
    showAlert(val) {
      this.$emit("input", val);
      if (!val) {
        this.$emit("close-alert");
      }
    },
  },
};
</script>

<style scoped>
.custom-alert {
  position: fixed; /* Fixed positioning */
  top: 16px; /* Adjust as needed */
  right: 16px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's above other elements */
}
</style>
