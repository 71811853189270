<template>
    <!-- Delete Dialog Component -->
    <v-dialog v-model="showDeleteUserDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          <span >Delete User</span>
        </v-card-title>
  
        <v-card-text class="mt-3">
          Are you sure you want to delete user {{ user.email }}?
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#8b1a29" @click="deleteUser(user)" class="text-white"
            >Yes</v-btn
          >
          <v-btn text @click="$emit('close-dialog')"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  
  export default {
    name: "delete-user",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showDeleteUserDialog: this.value,
      };
    },
    watch: {
      value(val) {
        this.showDeleteUserDialog = val;
      },
      showDeleteUserDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.$emit("close-dialog");
        }
      },
    },
    methods: {
      async deleteUser(item) {
        let alertData = {};
        let res = await this.$axios.delete(
          process.env.VUE_APP_API_URL+"/users/" + item.id
        );
        
        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-users");
        } else {
          this.alertText = res.data.message;
          this.alertType = "error";
        }
  
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  