<template>
  <v-dialog max-width="1050" v-model="showViewInvoiceDetailDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3">
        View Invoice Detail
      </v-card-title>

      <v-card-text class="mt-3">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.upc"
                label="UPC"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.description"
                label="Description"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.delivery_date"
                label="Delivery Date"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.size"
                label="Size"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.pack"
                label="Pack"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.quantity"
                label="Quantity"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.quantity_received"
                label="Quantity Received"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_price"
                label="RET Price"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_profit"
                label="RET Profit"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_gp"
                label="RET GP"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.case_price"
                label="Case Price"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.extended_case_price"
                label="Extended Case Price"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "view-invoice-detail-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    invoiceDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewInvoiceDetailDialog: this.value,
      localInvoiceDetailItem: { ...this.invoiceDetail }
    };
  },
  watch: {
    value(val) {
      this.showViewInvoiceDetailDialog = val;
    },
    showViewInvoiceDetailDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
