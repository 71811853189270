import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import router from "./router";
import store from "./store";
import axios from "axios";
import "@/assets/style.css";

Vue.config.productionTip = false;

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#8b1a29",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#1E88E5",
      },
    },
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});

// Create a flag to avoid multiple redirects
let isRedirecting = false;

// Set up a base instance of axios
const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage, Vuex store, or other storage methods
    const user = localStorage.getItem('grocer_user');
    if (user) {
      config.headers['Authorization'] = `Bearer ${JSON.parse(user).token}`;
      // config.headers['ngrok-skip-browser-warning'] = 'true';
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Reset the redirection flag on successful response
    isRedirecting = false;
    return response;
  },
  (error) => {
    // Check if the response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      // Only redirect if not already redirecting
      if (!isRedirecting) {
        isRedirecting = true; // Set the flag
        // Ensure the current route is not already the login page
        if (router.currentRoute.name !== "login") {
          // Clear any tokens or user data if necessary
          // localStorage.removeItem('authToken');
          // Redirect to the login page
          store.dispatch("logout");
          localStorage.removeItem('grocer_user');
          router.push("/login");
        }
      }
    }
    return Promise.reject(error); // Reject the promise to handle other errors
  }
);

Vue.prototype.$axios = axiosInstance;
export default axiosInstance;

new Vue({
  vuetify,
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
