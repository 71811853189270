<template>
  <v-app style="background-color: #e9e9e9;">
    <nav></nav>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
  }),
};
</script>
