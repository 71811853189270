<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Enter UPC"
            single-line
            hide-details
            class="w40"
            @input="getHistory"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.old_value`]="{ item }">
        <span class="text-align-center">{{
          item.old_value ? item.old_value : "-"
        }}</span>
      </template>
      <template v-slot:[`item.field`]="{ item }">
        <span class="text-capitalize">{{ item.field.replace(/_/g, " ") }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formattedDate(item.created_at) }}</span>
      </template>
    </v-data-table>
    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "history-listing",
  data() {
    return {
      search: "",
      totalItems: 100,
      loading: false,
      showAlert: false,
      alertData: {},
      itemsPerPage: 10,
      counter: 0,
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Field", value: "field", class: "table-header" },
        { text: "Old Value", value: "old_value", class: "table-header" },
        { text: "New Value", value: "new_value", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header" },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      dialog: false,
    };
  },
  components: {
    AlertComponent
  },
  mounted() {
    document.title = "Grocer App | Histories";
  },
  methods: {
    async getHistory() {
      try {
        this.loading = true;
        let items = await this.$axios.get(
          process.env.VUE_APP_API_URL+"/update-history",
          {
            params: {
              upc: this.search,
            },
          }
        );

        if (items.data && items.data.status) {
          this.items = items.data.data;
        } else {
          this.items = [];
          this.alertData.alertText = items.data.message;
          this.alertData.alertType = "error";
          this.showAlert = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.items = [];
        this.alertData.alertText = error.response.data.message;
        this.alertData.alertType = "error";
        this.showAlert = true;
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}
</style>
