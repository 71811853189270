<template>
  <v-dialog max-width="1050" v-model="showAddInvoiceDetailDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3">
        Add Invoice Detail
      </v-card-title>

      <v-card-text class="mt-3">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.upc"
                label="UPC"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.description"
                label="Description"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="localInvoiceDetailItem.delivery_date"
                    label="Delivery Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="localInvoiceDetailItem.delivery_date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.size"
                label="Size"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.pack"
                label="Pack"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.quantity"
                label="Quantity"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.quantity_received"
                label="Quantity Received"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_price"
                label="RET Price"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_profit"
                label="RET Profit"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.ret_gp"
                label="RET GP"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.case_price"
                label="Case Price"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                v-model="localInvoiceDetailItem.extended_case_price"
                label="Extended Case Price"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#8b1a29"
          @click="addInvoiceDetail(localInvoiceDetailItem)"
          style="color: white"
          >Add</v-btn
        >
        <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "add-invoice-detail-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    invoice_id: {},
  },
  data() {
    return {
      showAddInvoiceDetailDialog: this.value,
      localInvoiceDetailItem: {},
    };
  },
  watch: {
    value(val) {
      this.showAddInvoiceDetailDialog = val;
    },
    showAddInvoiceDetailDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    async addInvoiceDetail(item) {
      let alertData = {};
      let res = await this.$axios.post(
        process.env.VUE_APP_API_URL + "/invoice/data/row",
        { ...item, invoice_id: Number(this.invoice_id) }
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-invoice-details");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
