<template>
  <!-- Edit Dialog Componenet -->
  <v-container>
    <v-dialog v-model="showEditInventoryDialog" max-width="700" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Update Item
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc"
                    label="UPC"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sku"
                    label="SKU"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.vendor"
                    label="Vendor"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.category"
                    label="Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.subcategory"
                    label="Sub Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.price"
                    label="Price"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sale_price"
                    label="Sale Price"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.retail_price"
                    label="Retail Price"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.cost"
                    label="Cost"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.stock"
                    label="Stock"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.unit_size"
                    label="Unit Size"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.pack"
                    label="Pack"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.current_margin"
                    label="Current Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.new_margin"
                    label="New Margin"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.price_by_unit"
                    label="Price By Unit"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.sell_by_unit"
                    label="Sell By Unit"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="updateInventory(localInventoryItem)"
            style="color: white"
            >Update</v-btn
          >
          <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "edit-inventory-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inventoryItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditInventoryDialog: this.value,
      localInventoryItem: { ...this.inventoryItem },
    };
  },
  methods: {
    async updateInventory(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/inventories/" + item.id,
        {
          upc: item.upc,
          description: item.description,
          vendor: item.vendor,
          retail_price: item.retail_price,
          unit_size: item.unit_size,
          pack: item.pack,
          new_margin: item.new_margin,
          current_margin: item.current_margin,
        }
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-inventory");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
  },
  watch: {
    value(val) {
      this.showEditInventoryDialog = val;
    },
    showEditInventoryDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.$emit("close-dialog");
      }
    },
  },
};
</script>
