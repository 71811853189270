<template>
  <v-container>
    <v-btn @click="openFileDialog" class="button">
      <v-icon left>mdi-cloud-upload</v-icon>
      Upload Inventory
    </v-btn>
    <input
      type="file"
      ref="fileInput"
      style="display: none"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="submitFile"
    />
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  methods: {
    submitFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.file = files[0];
      }
      if (!this.file) return;
      this.$emit("start-loader");
      const formData = new FormData();
      formData.append("file", this.file);
      let alertData = {};
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/upload", formData)
        .then((res) => {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("stop-loader");
          this.$emit("reload-inventory");
          this.$emit("show-alert", alertData);
        })
        .catch((err) => {
          this.$emit("stop-loader");
          console.log(err);
        });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
.button {
  background-color: #8b1a29 !important;
  color: #fff;
  /* float: right; */
}

.v-btn__content .v-icon.v-icon--left {
  font-size: 22.5px;
}
</style>
